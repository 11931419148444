let hasWorker = false;
let hasNotifications = false;
let serviceWorkerPath = '/sw.js';
let beamsClient;
let userId = undefined;

const SID_KEY = 'interact_webchat_sid';
const INTERACT_NOTIFICATION_KEY = 'interact_webchat_unread_messages_count';
const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;

const setupServiceWorker = async (interest, register = true) => {
  try {
    if (!hasWorker) await navigator.serviceWorker.register(serviceWorkerPath);
    hasWorker = true;
    // Registration was successful
    const registration = await navigator.serviceWorker.ready;
    if (!beamsClient) {
      beamsClient = new PusherPushNotifications.Client({
        instanceId: '32b391aa-12d1-48c3-abf1-ab300b6888c7',
        serviceWorkerRegistration: registration,
      });
    }

    console.info('DEBUG: registering beamsdevice');

    if (register) {
      beamsClient
        .start()
        .then(() => beamsClient.clearDeviceInterests())
        .then(() => beamsClient.addDeviceInterest(interest))
        .catch(console.error);
    } else {
      beamsClient.stop();
    }
  } catch (e) {}
};

module.exports = class Interact {
  constructor(options) {
    this.options = options;
    this.buttonId = Date.now();
    this.chatId = Date.now();
    this.loaded = false;
    this.open = this.getDefaultOpenState(options);
    this.originalDocumentTitle = document.title;
    this.sessionId = this.getSessionId();
  }

  getDefaultOpenState(options) {
    let defaultOpen = false;
    if (options.openOnFirstTime) {
      if (!localStorage.getItem('interact_webchat_opened_first')) {
        localStorage.setItem('interact_webchat_opened_first', 'TRUE');
        localStorage.setItem('interact_webchat_open', 'OPEN');
        defaultOpen = true;
      }
    }
    if (!(options.openOnFirstTime && defaultOpen)) {
      defaultOpen = localStorage.getItem('interact_webchat_open') === 'OPEN';
    }
    return defaultOpen;
  }

  getSessionId() {
    let sessionId = sessionStorage.getItem(SID_KEY) ? sessionStorage.getItem(SID_KEY) : crypto.randomUUID();
    if (!sessionStorage.getItem(SID_KEY)) {
      sessionStorage.setItem(SID_KEY, sessionId);
    }
    return sessionId;
  }

  initialize(target) {
    this.target = target;
    const {
      channelId,
      chatUserId,
      slug,
      position,
      height,
      session = {},
      saveOpenState = false,
      droplet = false,
      withNotifications = false,
      notificationWorkerPath = '/sw.js',
      enableUpload = true,
      allowRestartConversation = false,
      welcomeMessage,
    } = this.options;
    const buttonLoaded = `width: 60px; height: 60px; position: absolute; ${
      position === 'RIGHT' ? 'right: 20px' : 'left: 20px'
    }; bottom: 15px`;
    let chatOpen = `box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    position: absolute;
    `;
    if (!slug) {
      chatOpen = chatOpen.concat(`
      max-width: 370px;
    width: calc(100% - 40px);
    height: calc(${height} - 120px); 
    ${position === 'RIGHT' ? 'right: 20px' : 'left: 20px'}; bottom: 85px; transition: height 0.5s;
      `);
    } else {
      chatOpen = chatOpen.concat(`
      width: 95%;
      height: calc(${height} - 20px);
      `);
    }
    const chatClosed = chatOpen.concat('height: 0px !important;');
    var button = document.createElement('iframe');
    if (withNotifications) {
      fetch(notificationWorkerPath, {
        referrer: '',
        referrerPolicy: 'strict-origin-when-cross-origin',
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
      }).then(r => {
        serviceWorkerPath = notificationWorkerPath;
        hasNotifications = r.headers.get('Content-Type') === 'application/javascript';
        button.allowtransparency = 'true';
        button.src = `${process.env.BUTTON_URL}${btoa(
          JSON.stringify({ channelId, open: this.open || !!slug, hideButton: !!slug, droplet, date: new Date() })
        )}`;
        button.frameBorder = 0;
        button.id = this.buttonId;
        button.name = `${Date.now()}`;
        target.appendChild(button, target);
        button.setAttribute('style', 'height: 0;');
        var chat = document.createElement('iframe');
        chat.src = `${process.env.CHAT_URL}${btoa(
          JSON.stringify({
            channelId,
            session,
            s: this.sessionId,
            hasNotifications,
            allowRestartConversation,
            welcomeMessage,
            h: height,
            date: new Date(),
            u: enableUpload,
            c: chatUserId,
            slug,
          })
        )}`;
        chat.frameBorder = 0;
        chat.id = this.chatId;
        chat.name = `${Date.now()}`;
        target.appendChild(chat, target);
        chat.setAttribute('style', 'display: none;');
        try {
          if ('serviceWorker' in navigator) {
            console.info('DEBUG: pre-pusher install');
            const pusherScript = document.createElement('scr' + 'ipt');
            pusherScript.type = 'text/javascript';
            pusherScript.async = true;
            pusherScript.src = 'https://js.pusher.com/beams/1.0/push-notifications-cdn.js';
            pusherScript.id = 'pusher-script';
            const sb = document.getElementsByTagName('script')[0];
            sb.parentNode.insertBefore(pusherScript, sb);
            console.info('pusher tag pushed, waiting to load');
            sb.addEventListener('load', () => {
              console.info('DEBUG: service-worker pre-ready');
              if (navigator.serviceWorker) {
                navigator.serviceWorker.register(notificationWorkerPath).catch(e => {
                  console.error(e);
                });
                navigator.serviceWorker.ready.then(registration => {
                  hasWorker = true;
                  console.info('DEBUG: service-worker ready promise resolved');
                  if (!beamsClient)
                    beamsClient = new PusherPushNotifications.Client({
                      instanceId: '32b391aa-12d1-48c3-abf1-ab300b6888c7',
                      serviceWorkerRegistration: registration,
                    });

                  beamsClient.getRegistrationState().then(status => {
                    hasNotifications = status === 'PERMISSION_GRANTED_REGISTERED_WITH_BEAMS';
                    console.info('DEBUG: permission granted registered with beams status reecived');
                  });
                });
              }
            });
            const audio = new Audio('https://minio.interact.dev.faktion.com/dev/not.wav');
            navigator.serviceWorker.addEventListener('message', event => {
              if (event.data && event.data.action === 'play-sound') {
                try {
                  audio.play();
                } catch (e) {}
              }
            });
          }
        } catch (e) {
          console.error(e);
        }
        window.onmessage = ({ data, origin }) => {
          if (!origin || !origin.includes('interact')) return;
          if (!data) return;
          try {
            const parsedData = JSON.parse(data);
            switch (parsedData.action) {
              case 'open':
                this.open = true;
                if (saveOpenState && this.loaded) {
                  localStorage.setItem('interact_webchat_open', 'OPEN');
                }
                chat.setAttribute('style', this.loaded ? chatOpen : chatClosed);
                chat.contentWindow.postMessage(JSON.stringify({ action: 'chat_open' }), '*');
                break;
              case 'user-created':
                userId = parsedData.id;
                if (isFirefox) return;
                setupServiceWorker(parsedData.id).then(() => {
                  if (navigator.serviceWorker && navigator.serviceWorker.controller)
                    navigator.serviceWorker.controller.postMessage(data);
                });
                break;
              case 'update-notifications':
                setupServiceWorker(userId, parsedData.settings.enabled);
                break;
              case 'close':
                this.open = false;
                if (saveOpenState && this.loaded) {
                  localStorage.setItem('interact_webchat_open', 'CLOSED');
                }
                chat.setAttribute('style', chatClosed);
                chat.contentWindow.postMessage(JSON.stringify({ action: 'chat_close' }), '*');
                break;
              case 'LOADED':
                chat.setAttribute('style', this.open ? chatOpen : chatClosed);
                button.setAttribute('style', buttonLoaded);
                this.loaded = true;
                x;
                break;
              case 'new_messages':
                try {
                  var newCount = +(localStorage.getItem(INTERACT_NOTIFICATION_KEY) || 0) + 1;
                  localStorage.setItem(INTERACT_NOTIFICATION_KEY, newCount);
                  document.title = `(${newCount}) ${this.originalDocumentTitle}`;
                  if (!hasNotifications) {
                    var audioElement = document.createElement('audio');
                    audioElement.preload = 'auto';
                    audioElement.id = 'interact_webchat_sound';
                    audioElement.autoplay = true;
                    audioElement.onplay = function () {
                      setTimeout(() => {
                        this.pause();
                      }, 2000);
                    };
                    const sourceElement = document.createElement('source');
                    sourceElement.src = 'https://minio.interact.dev.faktion.com/dev/not.wav';
                    sourceElement.type = 'audio/wav';
                    audioElement.appendChild(sourceElement);
                    document.body.appendChild(audioElement);
                  }
                  button.contentWindow.postMessage(
                    JSON.stringify({ ...parsedData, payload: { count: newCount } }),
                    '*'
                  );
                } catch (e) {}
                break;
              case 'read_messages':
                localStorage.setItem(INTERACT_NOTIFICATION_KEY, 0);
                document.title = this.originalDocumentTitle;
                const soundElement = document.getElementById('interact_webchat_sound');
                if (soundElement) soundElement.remove();
                button.contentWindow.postMessage(data, '*');
                break;
              default:
                break;
            }
          } catch (e) {
            window.postMessage(e, '*');
          }
        };
        window.onfocus = function () {
          chat.contentWindow.postMessage(JSON.stringify({ action: 'window_focus' }), '*');
        };
        window.onblur = function () {
          if (document.activeElement !== chat) {
            chat.contentWindow.postMessage(JSON.stringify({ action: 'window_blur' }), '*');
          }
        };
      });
    } else {
      button.allowtransparency = 'true';
      button.src = `${process.env.BUTTON_URL}${btoa(
        JSON.stringify({
          channelId,
          open: this.open || !!slug,
          droplet,
          hideButton: !!slug,
          hasNotifications,
          date: new Date(),
          session,
        })
      )}`;
      button.frameBorder = 0;
      button.id = this.buttonId;
      button.name = `${Date.now()}`;
      target.appendChild(button, target);
      button.setAttribute('style', 'height: 0;');
      var chat = document.createElement('iframe');
      chat.src = `${process.env.CHAT_URL}${btoa(
        JSON.stringify({
          allowRestartConversation,
          channelId,
          session,
          date: new Date(),
          h: height,
          u: enableUpload,
          c: chatUserId,
          s: this.sessionId,
          welcomeMessage,
          slug,
        })
      )}`;
      chat.frameBorder = 0;
      chat.id = this.chatId;
      chat.name = `${Date.now()}`;
      target.appendChild(chat, target);
      chat.setAttribute('style', 'display: none;');
      window.onmessage = ({ data, origin }) => {
        if (!origin || !origin.includes('interact')) return;
        if (!data) return;
        try {
          const parsedData = JSON.parse(data);
          switch (parsedData.action) {
            case 'open':
              this.open = true;
              if (saveOpenState && this.loaded) {
                localStorage.setItem('interact_webchat_open', 'OPEN');
              }
              chat.setAttribute('style', this.loaded ? chatOpen : chatClosed);
              chat.contentWindow.postMessage(JSON.stringify({ action: 'chat_open' }), '*');
              break;
            case 'user-created':
              userId = parsedData.id;
              if (isFirefox) return;
              setupServiceWorker(parsedData.id).then(() => {
                if (navigator.serviceWorker && navigator.serviceWorker.controller)
                  navigator.serviceWorker.controller.postMessage(data);
              });
              break;
            case 'update-notifications':
              if (hasNotifications && parsedData.settings.enabled) return;
              setupServiceWorker(userId, parsedData.settings.enabled);
              break;
            case 'close':
              this.open = false;
              if (saveOpenState && this.loaded) {
                localStorage.setItem('interact_webchat_open', 'CLOSED');
              }
              chat.setAttribute('style', chatClosed);
              chat.contentWindow.postMessage(JSON.stringify({ action: 'chat_close' }), '*');
              break;
            case 'LOADED':
              chat.setAttribute('style', this.open ? chatOpen : chatClosed);
              button.setAttribute('style', buttonLoaded);
              this.loaded = true;
              break;
            case 'new_messages':
              try {
                var newCount = +(localStorage.getItem(INTERACT_NOTIFICATION_KEY) || 0) + 1;
                localStorage.setItem(INTERACT_NOTIFICATION_KEY, newCount);
                document.title = `(${newCount}) ${this.originalDocumentTitle}`;
                if (!hasNotifications) {
                  var audioElement = document.createElement('audio');
                  audioElement.preload = 'auto';
                  audioElement.id = 'interact_webchat_sound';
                  audioElement.autoplay = true;
                  audioElement.onplay = function () {
                    setTimeout(() => {
                      this.pause();
                    }, 2000);
                  };
                  const sourceElement = document.createElement('source');
                  sourceElement.src = 'https://minio.interact.dev.faktion.com/dev/not.wav';
                  sourceElement.type = 'audio/wav';
                  audioElement.appendChild(sourceElement);
                  document.body.appendChild(audioElement);
                }
                button.contentWindow.postMessage(JSON.stringify({ ...parsedData, payload: { count: newCount } }), '*');
              } catch (e) {}
              break;
            case 'read_messages':
              localStorage.setItem(INTERACT_NOTIFICATION_KEY, 0);
              document.title = this.originalDocumentTitle;
              const soundElement = document.getElementById('interact_webchat_sound');
              if (soundElement) soundElement.remove();
              button.contentWindow.postMessage(data, '*');
              break;
            case 'restart-conversation':
              this.restart();
              break;
            default:
              break;
          }
        } catch (e) {
          window.postMessage(e, '*');
        }
      };
      window.onfocus = function () {
        chat.contentWindow.postMessage(JSON.stringify({ action: 'window_focus' }), '*');
      };
      window.onblur = function () {
        if (document.activeElement !== chat) {
          chat.contentWindow.postMessage(JSON.stringify({ action: 'window_blur' }), '*');
        }
      };
    }
    // Initializing the eventListeners to handle communication with the event handler.
  }

  restart() {
    localStorage.removeItem(INTERACT_NOTIFICATION_KEY);
    sessionStorage.removeItem(SID_KEY);

    this.buttonId = Date.now();
    this.chatId = Date.now();
    this.loaded = false;
    this.open = this.getDefaultOpenState(this.options);
    this.sessionId = this.getSessionId();
    this.target.replaceChildren();
    this.initialize(this.target);
  }
};
